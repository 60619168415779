import React from 'react';
import parse from 'html-react-parser';
import * as styles from './TextSection.module.scss';
import clsx from 'clsx';

const TextSection = ({ text = '', className, bgClassName }) => {

  return (
    <section className={clsx(bgClassName && bgClassName, className && className, styles.textSection)}>
      <div className={clsx('section-wrapper', styles.wrapper)}>
        <div className={styles.text}>
          { text && text.map((item, i) => <span key={i}>{parse(item)}</span>) }
        </div>
      </div>
    </section>
  )
};

export default TextSection;
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Hero from '../components/Hero/Hero';
import TextSection from '../components/TextSection/TextSection';

export default function Article({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero
          className={'bg-bio'}
          text={data.post.data.name.html}
          work_title={data.post.data.work_title.text}
          image={data.post.data.bio_image} />
      
        <TextSection text={[data.post.data.bio_text.html]} />
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.post.seo.title && data.post.seo.title.text}
      description={data.post.seo.description && data.post.seo.description.text}
      image={data.post.seo.image && data.post.seo.image.url}
      keywords={''} />
  )

export const postQuery = graphql`
  query Post($slug: String) {
    post: prismicBio(id: { eq: $slug }) {
      id
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      bio_text {
        html
      }
      name {
        html
      }
      work_title {
        text
      }
      work_title {
        html
      }
      linkedin {
        url
      }
      bio_image {
        url
      }
    }
    }
  }
`

import clsx from 'clsx';
import parse from 'html-react-parser';
import React from 'react';
import * as styles from './Hero.module.scss';

export default function Hero({ className, text, work_title, image }) {
  return(
    <section className={clsx(styles.hero, className)}>
      <div className={clsx(styles.sectionWrapper, 'section-wrapper')}>
        <div className={ styles.text }>{ parse(text) }          
            {
              work_title && <div className={ styles.work_title }>{work_title}</div>    
            }          
        </div>
        {
          image && <div className={ styles.image } style={{
            backgroundImage: `url(${image.url})`
          }}>
          </div>
        }
      </div>
    </section>
  )
}